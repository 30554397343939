import React from "react";

import SavedProducts from "../../components/pages/TechnicShop/components/SavedProducts";
import Page from "../../components/shared/Page";

const TechnicSavedProducts = () => {
    return (
        <Page>
            <SavedProducts />
        </Page>
    );
};

export default TechnicSavedProducts;
